body{
    background: $black;
    // Font family and size
    font-family: 'Neuzeit', serif;
    font-weight: 400;
    color: $white;
    width: 100%;
	max-width: $innerWidth;
	margin:0 auto;
	background-image:url('../img/background.jpg');
	background-repeat: no-repeat;
	background-position: top center;
	line-height:1.3;
	font-size:16px;
}

img{
	max-width: 100%;
}

p{
	margin:0 auto;
	padding:30px 0;
	letter-spacing: 1px;
	//font-size:24px;
}

.cta{
	display: block;
	background: $white;
	color:$black;
	padding:15px;
	text-decoration: none;
	text-transform: uppercase;
	font-weight:700;
	margin: 0 auto;
	max-width:250px;
}
.container{
	z-index:1;
	//max-width: $innerWidth;
	//margin: 0 auto;
	position: relative;
}

.header{
	background: $black;
	text-align: center;
	padding:30px 0px; 
}

.hero, .signature, .limited-editions, .gift-wrap{
	text-align: center;
	margin:0 auto;
	width:60%;

}
.hero{
	p{
		padding:50px 0;
		padding-bottom: 30px;
	}
	
	img{
		margin-bottom:-5px;
		width:100%;
	}

	&__title{
		margin:0 auto;
		padding:50px 0;
		font-size:2.750em;
		text-transform: uppercase;
		border-top:3px solid white;
		border-bottom:3px solid white;
	}
}

.limited-editions{
	&__title{
		text-transform: uppercase;
		font-size:30px;
		font-weight:300;
		margin:0 auto;
		padding-bottom:120px;
		
		&:before{
			content:"";
			border-top:3px solid white;
			width:8%;
			height:50px;
			display: block;
			text-align: center;
			margin-left:46%;

		}
	}

	&__products{
		padding:20px;
		
		&--fleft{
			width:45%;
			float:left;
		}
		&--fright{
			width:45%;
			float:right;   
		}

		img{
			max-width:100%;
			max-height: 280px;
		}
		.title{
			text-transform: uppercase;	
			line-height:1.4;		
		}
		p{
			padding:10px 0;
		}
		.price{
			padding-bottom:20px;
			font-weight:700;
		}
	}
	:after{
		clear: both;
		content: "";
		display: block;
	}

}

.gift-wrap{
	padding-top:130px;
	&__title{
		text-transform: uppercase;
		font-size:30px;
		font-weight:300;
		margin:0 auto;
		padding:90px 0px; 
		width:80%;
		&--border{
			padding:0px; 
			&:after{
				content:"";
				border-bottom:3px solid white;
				width:8%;
				height:50px;
				display: block;
				text-align: center;
				margin-left:46%;
	
			}
		}
	}

	&__subtitle{
		text-transform: uppercase;
		font-weight: 700;
	}

	&__content{
		width:50%;
		padding:0;
	}



}

.branches{
	&:after{
		content:"";
		display: block;
		background-image: url(../img/branches-video.png);
		background-repeat: no-repeat;
		background-position: bottom center;
		padding: 0;
		padding-bottom: 25%;
		background-size: contain;
		margin-top: -4px;
	}
}

.signature--viktor--rolf{
	padding:100px 40px;
}





/*  SECTIONS  */
.section {
	clear: both;
	padding: 0px;
	margin: 0px;
}

.reversed{

    margin-top: 40px;
	}


/*  COLUMN SETUP  */
.col {
	display: block;
	float:left;
	margin: 1% 0 1% 1.6%;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }
/*  GRID OF TWO  */
.span_2_of_2 {
	width: 100%;
}
.span_1_of_2 {
	width: 49.2%;
}

/*  GO FULL WIDTH AT LESS THAN 480 PIXELS */

