/* roboto-regular - latin */
@font-face {
  font-family: 'Neuzeit';
  font-style: normal;
  font-weight: 400;
  src: local('Neuzeit'), local('Neuzeit'),
       url('../fonts/Neuzeit-S-LT-Std-Book_32182.ttf') format('truetype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Neuzeit-S-LT-Std-Book_32182.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Neuzeit-S-LT-Std-Book_32182.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Neuzeit-S-LT-Std-Book_32182.eot') format('embedded-opentype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* roboto-700 - latin */
/* roboto-regular - latin */
@font-face {
  font-family: 'Neuzeit Heavy';
  font-style: normal;
  font-weight: 700;
  src: local('Neuzeit Heavy'), local('Neuzeit Heavy'),
       url('../fonts/Neuzeit-S-LT-Std-Book-Heavy_32181.ttf') format('truetype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Neuzeit-S-LT-Std-Book-Heavy_32181.woff') format('woff'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Neuzeit-S-LT-Std-Book-Heavy_32181.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/Neuzeit-S-LT-Std-Book-Heavy_32181.eot') format('embedded-opentype'), /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* roboto-700 - latin */
