/*
	Adjusting sizes
*/


@media only screen and (max-width: 1000px) {


	.limited-editions{
		width:80%;
	}


}


@media (max-width: 768px) {
	body{
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: 130%;
	}
	.hero, .signature, .limited-editions, .gift-wrap{
		width:80%;
	}

	.limited-editions{
		&__products{
		
			.title{margin:0 auto;}
			&--fleft{
				width:100%;
				float:left;
			}
			&--fright{
				width:100%;
				float:right;   
			}
		}
	}
	
	.hero__title{
		font-size:1.7em;
	}
	
	.limited-editions__title, .gift-wrap__title, {
		font-size:1.25em;
	}

	.gift-wrap__title, .gift-wrap__content{
		width:100%;


	}

	
	
}

@media only screen and (max-width: 768px) {
	.col { 
		margin: 1% 0 1% 0%;
	}
}

@media only screen and (max-width: 768px) {
	.span_2_of_2, .span_1_of_2 { width: 100%; }
	
	
	.reversed{
		display:flex;
		flex-direction:column-reverse;
		margin-top:20px;
	}
}


@media (max-width: 480px) {

	.hero, .signature, .limited-editions, .gift-wrap{
		width:95%;
	}



}