/**
    Font functions
    @param $family : name
    @param $size : photoshop size in pixels
    @letterSpacing : Photoshop letter spacing (no unit)
    @lineHeight : Photoshop line-height in pixels
**/
@mixin font( $family, $size, $fontWeight: 400, $letterSpacing : 0 , $lineHeight: 1 ) {

    @if $lineHeight != 1{
		$lineHeight : ( $lineHeight / $size );
   	}

	font: ( ( $size / $defaultFontSize ) * 1rem )#{"/"}( $lineHeight ) "#{$family}", Arial, Helvetica, sans-serif;
    font-weight: $fontWeight;

   	@if $letterSpacing != 0{
		letter-spacing: ( $letterSpacing / 1000 ) * 1em;
   	}


}

/// Only values
@mixin fontValue( $size , $letterSpacing : 0 , $lineHeight: 1 ) {

    @if $lineHeight != 1{
		$lineHeight : ( $lineHeight / $size );
   	}

    font-size: ( $size / $defaultFontSize ) * 1rem;
    line-height: $lineHeight;

   	@if $letterSpacing != 0{
		letter-spacing: ( $letterSpacing / 1000 ) * 1em;
   	}


}

@mixin hide-text() {
    /* Hide the text. */
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

@function getPercent($size, $parent){
   @return ($size/$parent) * 100%
}


@mixin link--big-hover(){

        &:after{
            transform: scaleY(1);
            height: 10px;
        }

        &:before{
            transform: scaleY(0);
        }
    
}
